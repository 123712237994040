document.addEventListener('DOMContentLoaded', function (e) {
    /** On récupère la liste de tous les emplacements de contenu Quill */
    const diplay_containers = document.querySelectorAll('.quill-display-container');

    /** Pour chaque emplacement */
    for (const container of diplay_containers) {
        /** On obtient le conteneur d'affichage et celui des données */
        const display = container.querySelector('.quill-display');
        const data = container.querySelector('.quill-display-data');

        /** On vide l'affichage du contenu prérendu */
        display.innerHTML = '';

        /** On crée un éditeur en lecture seule, avec le theme bubble  */
        const quill = new Quill(display, {
            readOnly: true,
            theme: 'bubble'
        });

        try {
            /** On parse les données et on les affiches dans l'éditeur */
            const content = JSON.parse(data.textContent);
            quill.setContents(content);
        } catch { /** Si la valeur existante est un JSON valide, on utilise cette valeur, sinon, on ne fait rien de spécial et l'éditeur sera vide. */ }
    }
});
