document.addEventListener('DOMContentLoaded', function (e) {
    const type_prix_select = document.getElementById('type-prix-select');
    const tranche_prix_table = document.getElementById('tranches-prix-list');

    const fieldset_prix_unit = document.getElementById('type-prix-unit');
    const fieldset_prix_degr = document.getElementById('type-prix-degr');

    const tranche_prix_template = document.getElementById('template-tranche-prix-row');

    if(type_prix_select instanceof HTMLElement
    && tranche_prix_table instanceof HTMLElement
    && fieldset_prix_unit instanceof HTMLElement
    && fieldset_prix_degr instanceof HTMLElement
    && tranche_prix_template instanceof HTMLElement) {
        let row_index = Array.from(tranche_prix_table.children).filter(function (element) {
            return element.matches('tr');
        }).length;

        function switchTypePrix(e) {
            switch (type_prix_select.value) {
                case 'UNIT':
                    fieldset_prix_unit.classList.remove('hidden');
                    fieldset_prix_unit.disabled = false;
                    fieldset_prix_degr.classList.add('hidden');
                    fieldset_prix_degr.disabled = true;
                    break;
                case 'DEGR':
                    fieldset_prix_degr.classList.remove('hidden');
                    fieldset_prix_degr.disabled = false;
                    fieldset_prix_unit.classList.add('hidden');
                    fieldset_prix_unit.disabled = true;
                    break;
            }
        }

        type_prix_select.addEventListener('change', switchTypePrix);

        tranche_prix_table.addEventListener('click', function (e) {
            if (e.target.closest('.add-tranche-prix')) {
                const clone = tranche_prix_template.content.cloneNode(true);

                const min = clone.querySelector('input[data-name="min"]');
                const max = clone.querySelector('input[data-name="max"]');
                const prix = clone.querySelector('input[data-name="prix"]');

                min.name = `tranches[${row_index}][min]`;
                max.name = `tranches[${row_index}][max]`;
                prix.name = `tranches[${row_index}][prix]`;

                tranche_prix_table.appendChild(clone);
                row_index++;
            }
            if (e.target.closest('.remove-tranche-prix')) {
                const parent = e.target.closest('tr');
                if (parent !== null) {
                    parent.remove();
                    /**
                     * On ne décrémente pas l'index puisqu'on ne supprime pas nécessairement la dernière ligne.
                     * L'intérêt est par ailleurs de grouper un min, un max, et un prix ensemble, sans collision
                     * pas spécialement de les ordonner.
                     */
                }
            }
        })

        switchTypePrix();
    }
});
