document.addEventListener('DOMContentLoaded', function (e) {
    function change_button(button, status) {
        const allow = button.dataset.allowSend === status;
        button.disabled = !allow;
        button.classList.toggle('cursor-not-allowed', !allow);
        button.classList.toggle('bg-red-light', allow);
        button.classList.toggle('bg-grey', !allow);
    }

    const selector_status = document.querySelector('select[name="status"]');
    const button_send_expedition_mail = document.querySelector('button[name="envoi_expedition"]');

    if (selector_status instanceof HTMLSelectElement && button_send_expedition_mail instanceof HTMLButtonElement) {
        selector_status.addEventListener('change', function (e) {
            change_button(button_send_expedition_mail, selector_status.value);
        });

        change_button(button_send_expedition_mail, selector_status.value);
    }
});
