require('./bootstrap');
require('./admin/boutique/commandes');
require('./admin/boutique/frais-port');
require('./admin/boutique/produits');
require('./admin/client/paiement');
require('./admin/comments');
require('./admin/echantillon/edit');
require('./admin/focus/editor');
require('./admin/params/zones-ups');
require('./admin/params/zones-ups-price');

require('./client/boutique');

require('./public/inscription');
require('./public/quill-display');

import {trans} from "./translations";

/**
 * @param {string} password_str
 */
function validate_password(password_str) {
    return /[a-z]/g.test(password_str) && /[A-Z]/g.test(password_str) && /[0-9]/g.test(password_str) && /[^a-zA-Z\d]/g.test(password_str) && password_str.length >=8;
}

document.addEventListener('DOMContentLoaded', function (e) {
    /** @type {NodeListOf<HTMLInputElement>} */
    const csrf_token_inputs = document.querySelectorAll('input[name="_token"]');
    // Pour tous les formulaires protégés par un token CSRF, on mes à jour le token avant de l'envoyer, pour éviter les erreur de type 419 parce que la page était en cache et le token a expiré depuis.
    for(let input of csrf_token_inputs) {
        let updated_csrf_token = false;
        input.form.addEventListener('submit', function (e) {
            if(!updated_csrf_token) { // Si le token csrf n'a pas été rechargé
                e.preventDefault(); // Alors on l'empêche d'être effectué, le temps qu'on récupère le token CSRF.
                axios.get('/csrf-token').then(function (val) { // On récupère le token CSRF (qui est dépendant le la session et ne peut pas être obtenu depuis un autre domain) https://iaincollins.medium.com/csrf-tokens-via-ajax-a885c7305d4a
                    input.value = val.data; // On update celui présent dans le formulaire
                    updated_csrf_token = true;
                    e.submitter.click(); // Et on re-submit le form
                    //input.form.requestSubmit ? input.form.requestSubmit() : input.form.submit(); // Et on re-submit le form
                });
            }
            // Si le token csrf a été rechargé, c'est qu'il ne s'agit pas de celui par défaut, mais de celui généré par cette fonction, après avoir mis à jour le token, donc on a rien à faire.
        });
    }

    /** @type {NodeListOf<HTMLElement>} */
    const password_containers = document.querySelectorAll('.password-container');
    for (const container of password_containers) {
        const validation_message = {};
        const password_input = container.querySelector('input');
        const password_show_button = container.querySelector('.show-password');
        const password_hidden = password_show_button.querySelector('.password-hidden');
        const password_visible = password_show_button.querySelector('.password-visible');

        password_show_button.addEventListener('click', function (e) {
            switch (password_input.type) {
                case 'password':
                    password_input.type = 'text';
                    password_hidden.classList.add('hidden');
                    password_visible.classList.remove('hidden');
                    break;
                case 'text':
                    password_input.type = 'password';
                    password_hidden.classList.remove('hidden');
                    password_visible.classList.add('hidden');
                    break;
            }
        });

        /** On vérifie qu'il s'agit d'un mot de passe qu'on valide. */
        if(container.classList.contains('password-validation')) {
            password_input.addEventListener('keyup', function (e) {
                if (validate_password(password_input.value)) {
                    delete validation_message.complexite;
                } else {
                    validation_message.complexite = trans("Votre mot de passe doit faire au minimum 8 caractères, contenir 1 minuscule, 1 majuscule, 1 chiffre et 1 caractère spécial.");
                }

                password_input.setCustomValidity(Object.values(validation_message).join("\n"));
            });
        }

        /** S'il existe un champ de confirmation associé au champ password, alors on vérifie que les deux sont égaux. */
        const confirm_password = password_input.form.elements.namedItem(`${password_input.name}_confirmation`);
        if(confirm_password instanceof HTMLInputElement) {
            const password_confirmation = function () {
                password_input.value === confirm_password.value
                    ? delete validation_message.confirmation
                    : validation_message.confirmation = trans('Le mot de passe et sa confirmation ne correspondent pas.');
                password_input.setCustomValidity(Object.values(validation_message).join("\n"));
                confirm_password.setCustomValidity(validation_message.confirmation ?? '');
            }
            password_input.addEventListener('change', password_confirmation);
            confirm_password.addEventListener('change', password_confirmation);
        }
    }

    /** @type {NodeListOf<HTMLFieldSetElement>} */
    const millesime_containers = document.querySelectorAll('fieldset.millesime-container');
    for (const container of millesime_containers) {
        /** @type {HTMLInputElement} */
        const millesime_custom = container.querySelector('input.millesime-custom');
        millesime_custom.disabled = (container.form.elements[container.name].value !== 'cust');
        container.addEventListener('change', function (e) {
            /**
             * Si le bouton radio sélectionné est celui du champ de saisie "libre" alors on l'active. Sinon il est désactivé.
             */
            millesime_custom.disabled = (container.form.elements[container.name].value !== 'cust');
        });
    }

    /** @type {NodeListOf<HTMLFieldSetElement>} */
    const doc_containers = document.querySelectorAll('fieldset.document-container');
    for (const container of doc_containers) {
        const envoi_doc_radio = container.form.elements['envoi_doc'];
        if(envoi_doc_radio instanceof RadioNodeList) {
            container.form.addEventListener('change', function (e) {
                container.disabled = (envoi_doc_radio.value !== 'INT'); // 'INT' pour "envoi par internet
            })
            container.disabled = (envoi_doc_radio.value !== 'INT');
        }
    }

    const marque_containers = document.querySelectorAll('fieldset.marque-container');
    for (const container of marque_containers) {
        /** @type {HTMLTemplateElement} */
        const marque_template = container.querySelector('template.marque-template');
        let row_index = container.querySelectorAll('.marque-entry').length;

        container.addEventListener('click', function (e) {
            if(e.target.closest('.add-marque') && container.querySelectorAll('.marque-entry').length < 2) {
                const clone = marque_template.content.cloneNode(true);
                const input = clone.querySelector('input');

                input.name = `${container.name}[${row_index}]`;
                container.appendChild(clone);

                row_index++;
            }
            if(e.target.closest('.remove-marque')) {
                const parent = e.target.closest('.marque-entry');
                if(parent !== null) {
                    parent.remove();
                    /**
                     * On ne décrémente pas l'index puisqu'on ne supprime pas nécessairement la dernière ligne.
                     * On a d'ailleurs pas spécialement d'intérêt à ordonner les marques ?
                     */
                }
            }
        });
    }

    /** @type {NodeListOf<HTMLInputElement>} */
    const file_inputs = document.querySelectorAll('input[data-max-file-size]');
    for (const input of file_inputs) {
        input.addEventListener('change', function (e) {
            if(input.dataset.maxFileSize !== undefined) {
                let exceed_max_size = false;
                for(const file of input.files) {
                    exceed_max_size |= file.size > input.dataset.maxFileSize;
                }

                if (exceed_max_size) {
                    input.setCustomValidity(trans("Le fichier sélectionné doit faire moins de :value Mo.", {'value':input.dataset.maxFileSize / (1024 * 1024)}));
                }
                else {
                    input.setCustomValidity('');
                }

                input.reportValidity();
            }
        })
    }

    const checkbox_sets = document.querySelectorAll('.checkbox-set');
    for (const checkbox_set of checkbox_sets) {
        const checkboxs = checkbox_set.querySelectorAll('input[type="checkbox"]');
        checkbox_set.addEventListener('change', function (e) {
            const count = [...checkboxs].reduce(function (accumulator, checkbox) {
                return checkbox.checked ? accumulator + 1 : accumulator;
            }, 0)

            if ((checkbox_set.dataset.checkboxMin !== undefined) && (count < parseInt(checkbox_set.dataset.checkboxMin))) {
                for (const checkbox of checkboxs) {
                    checkbox.setCustomValidity(trans('Au moins :count case doit être sélectionnée.', {count: parseInt(checkbox_set.dataset.checkboxMin)}));
                    checkbox.reportValidity();
                }
            } else {
                for (const checkbox of checkboxs) {
                    checkbox.setCustomValidity('');
                }
            }
        });
    }
});
