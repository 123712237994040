document.addEventListener('DOMContentLoaded', function (e) {
    function selectAllChange(options, zone) {
        for (const opt of options) {
            opt.selected = opt.dataset.zone !== zone;
        }
    }

    const select_zone_eu = document.getElementById('zone-eu');
    const select_zone_exp = document.getElementById('zone-exp');

    const button_move_to_exp = document.getElementById('move-to-zone-exp');
    const button_move_to_eu = document.getElementById('move-to-zone-eu');

    const form_frais_port = document.getElementById('frais-port-form');

    if(button_move_to_exp instanceof HTMLElement
    && button_move_to_eu instanceof HTMLElement
    && select_zone_eu instanceof HTMLElement
    && select_zone_exp instanceof HTMLElement) {
        button_move_to_exp.addEventListener('click', function (e) {
            const selected_options = [...select_zone_eu.selectedOptions];
            for (const opt of selected_options) {
                select_zone_exp.appendChild(opt);
            }
        });

        button_move_to_eu.addEventListener('click', function (e) {
            const selected_options = [...select_zone_exp.selectedOptions];
            for (const opt of selected_options) {
                select_zone_eu.appendChild(opt);
            }
        });
    }
    if(form_frais_port instanceof HTMLElement) {
        form_frais_port.addEventListener('submit', function (e) {
            selectAllChange(select_zone_eu.options, 'eu');
            selectAllChange(select_zone_exp.options, 'exp');
        });
    }
});
