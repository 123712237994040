import {trans} from "../translations";

document.addEventListener('DOMContentLoaded', function (e) {
    /** Listes des appellations, par pays */
    const pays_appellations = new Map();
    const currency_formatter = new Intl.NumberFormat(document.documentElement.lang, {style: 'currency', currency: 'EUR'});
    const percent_formatter = new Intl.NumberFormat(document.documentElement.lang, {style: 'percent'});

    /**
     * @param {HTMLOptionElement} pays_option
     * @param {HTMLOutputElement} prefix
     * @param {HTMLInputElement} input
     * @param {HTMLSpanElement} required_tag
     */
    function updateTVA(pays_option, prefix, input, required_tag) {
        const prefix_string = pays_option.dataset.prefixTva;
        let min = parseInt(pays_option.dataset.tvaMin);
        let max = parseInt(pays_option.dataset.tvaMax);
        let prefix_length = 0;

        const required = (pays_option.dataset.numFiscalRequis === '1');
        input.required = required;
        prefix.required = required;
        required_tag.classList.toggle('hidden', !required);

        if(prefix_string !== '' && prefix_string !== undefined) {
            prefix.value = prefix_string;
            prefix.classList.remove('hidden');
            prefix_length = prefix_string.length;
        }
        else {
            prefix.classList.add('hidden');
        }

        max = isNaN(max) ? 17 : Math.min(max, 17); // max ne peut pas valoir plus que 17 (le max dans la base).
        max = max - prefix_length; // si on a un préfixe, celui-ci doit être préremplis, donc on retire sa longueur du maximum.
        max = Math.max(max, 0); // et max ne peut pas être négatif.

        min = isNaN(min) ? 0 : Math.max(min - prefix_length, 0); // min ne peut pas être négatif.
        min = Math.min(min, max); // min ne peut pas être plus grand que max.

        // on retire les deux attributs avant de les modifier, pour éviter les erreurs du type min > max ou inversement.
        input.removeAttribute('minlength');
        input.removeAttribute('maxlength');

        input.maxLength = max;
        input.minLength = min;
    }

    /**
     * @param {HTMLOptionElement} pays_option
     * @param {HTMLOutputElement} prefix
     */
    function updatePhone(pays_option, prefix) {
        const prefix_string = pays_option.dataset.prefixTel;

        if(prefix_string !== '' && prefix_string !== undefined) {
            prefix.value = `+${prefix_string}`;
            prefix.classList.remove('hidden');
        }
        else {
            prefix.classList.add('hidden');
        }
    }

    /**
     * @param {HTMLSelectElement} pays_selector
     * @param {HTMLSelectElement} appellation_selector
     */
    function updateAppellations(pays_selector, appellation_selector) {
        const pays_id = pays_selector.value;
        appellation_selector.value = ''; // On remet le select sur la valeur par défaut
        appellation_selector.disabled = true;
        appellation_selector.closest('label').classList.add('loading');
        for(const option of [...appellation_selector.options]) {
            if(option.value !== '') { // On garde l'option avec une valeur vide
                option.remove(); // On détache toute les autres options
            }
        }
        if(pays_id !== '') { // Si le pays sélectionné n'est pas l'option vide
            if (pays_appellations.has(pays_id)) { // Si on a déjà une liste des options disponibles pour ce pays
                updateAppellationOptions(appellation_selector, pays_appellations.get(pays_id));
            }
            else { // Sinon, on appelle l'API
                pays_appellations.set(pays_id, new Map());
                getPaysAppellations(`/api/pays/${pays_id}/appellations`, pays_id, appellation_selector, pays_selector);
            }
        }
        else {
            appellation_selector.disabled = false;
            appellation_selector.closest('label').classList.remove('loading');
        }
    }

    /**
     * @param {string} url
     * @param {string} pays_id
     * @param {HTMLSelectElement} appellation_selector
     * @param {HTMLSelectElement} pays_selector
     */
    function getPaysAppellations(url, pays_id, appellation_selector, pays_selector) {
        const selected_pays_appellations = pays_appellations.get(pays_id);
        axios.get(url) // On appelle l'API
            .then(function (response) {
                const response_data = response.data; // On récupère la réponse de l'API
                for(const appellation of response_data.data) { // On crée les HTMLOptionElement pour chaque appellation
                    selected_pays_appellations.set(appellation.id, createAppellationOption(appellation));
                }
                if(response_data.links.next) { // S'il y a une page suivant, on la charge
                    getPaysAppellations(response_data.links.next, pays_id, appellation_selector, pays_selector);
                }
                else if(pays_id === pays_selector.value) { // Sinon, si le pays sélectionné à l'origine est toujours le pays actif, on remplis le selector avec les options correspondantes
                    updateAppellationOptions(appellation_selector, selected_pays_appellations);
                }
            });
    }

    /**
     * @param {Object} appellation
     * @returns {HTMLOptionElement}
     */
    function createAppellationOption(appellation) {
        const opt = document.createElement('option');
        opt.value = appellation.id;
        opt.textContent = appellation.libelle;
        return opt;
    }

    /**
     * @param {HTMLSelectElement} selector
     * @param {Map<any, HTMLOptionElement>} options
     */
    function updateAppellationOptions(selector, options) {
        const default_value = JSON.parse(selector.dataset.default);
        for(const [id, opt] of options) {
            if (Array.isArray(default_value)) {
                opt.selected = default_value.includes(opt.value);
            } else {
                opt.selected = opt.value === selector.dataset.default;
            }
            selector.appendChild(opt);
        }
        selector.disabled = false;
        selector.closest('label').classList.remove('loading');
    }

    /**
     * Mes à jour le récap de la commande en fonction de la promotion et de la TVA qui lui sont appliqués.
     * @param {HTMLElement} recap
     * @param {HTMLInputElement|null} promo
     * @param {HTMLOptionElement} pays
     */
    function updateRecap(recap, promo, pays) {
        const promo_row = recap.querySelector('.recap-prix-promo');
        const total_ht = recap.querySelector('.recap-prix-ht-montant');
        const libel_tva = recap.querySelector('.recap-prix-tva-libelle');
        const total_tva = recap.querySelector('.recap-prix-tva-montant');
        const total_ttc = recap.querySelector('.recap-prix-ttc-montant');

        const montant_inscription_ht = parseFloat(recap.dataset.inscriptionHt);
        const montant_port_ht = parseFloat(recap.dataset.portHt);
        const taux_tva = parseFloat(pays.dataset.tauxTva);
        const tva_applicable = (pays.dataset.numFiscalRequis === '1') || (recap.dataset.clientNumFiscalEmpty === "1");

        libel_tva.textContent = trans('TVA (:taux)', {taux: percent_formatter.format(tva_applicable ? taux_tva : 0)});

        if(promo === null || promo.value === '') {
            promo_row.classList.add('hidden');
            total_ht.textContent = currency_formatter.format(montant_inscription_ht);
            if (tva_applicable) {
                total_tva.textContent = currency_formatter.format((montant_inscription_ht + montant_port_ht) * taux_tva);
                total_ttc.textContent = currency_formatter.format((montant_inscription_ht + montant_port_ht) * (1 + taux_tva));
            } else {
                total_tva.textContent = currency_formatter.format(0);
                total_ttc.textContent = currency_formatter.format((montant_inscription_ht + montant_port_ht));
            }
        }
        else {
            const promo_montant = recap.querySelector('.recap-prix-promo-montant');
            const promo_amount = parseFloat(promo.dataset.amount);
            promo_montant.textContent = currency_formatter.format(promo_amount);
            total_ht.textContent = currency_formatter.format(montant_inscription_ht - promo_amount);
            if (tva_applicable) {
                total_tva.textContent = currency_formatter.format((montant_inscription_ht + montant_port_ht - promo_amount) * taux_tva);
                total_ttc.textContent = currency_formatter.format((montant_inscription_ht + montant_port_ht - promo_amount) * (1 + taux_tva));
            } else {
                total_tva.textContent = currency_formatter.format(0);
                total_ttc.textContent = currency_formatter.format((montant_inscription_ht + montant_port_ht - promo_amount));
            }
            promo_row.classList.remove('hidden');
        }
    }

    /**
     * @param {RadioNodeList|null} radio_list
     * @returns {node|null}
     */
    function findChecked(radio_list) {
        let node = null;

        if (radio_list instanceof RadioNodeList) {
            for (const radio of radio_list) {
                if (radio.checked) {
                    node = radio;
                    break;
                }
            }
        }

        return node;
    }

    /** @type {HTMLFormElement} */
    const form_inscription = document.getElementById('inscription-form');
    /** @type {HTMLSelectElement} */
    const selector_pays = document.getElementById('pays-selector');
    const tva_groups = [...document.querySelectorAll('.tva-group')].map(function (val) {
        return {
            input: val.querySelector('.tva-input'),
            prefix: val.querySelector('.tva-prefix'),
            required: val.querySelector('.tva-required'),
        };
    });
    const tel_groups = [...document.querySelectorAll('.tel-group')].map(function (val) {
        return {
            input: val.querySelector('.tel-input'),
            prefix: val.querySelector('.tel-prefix'),
        };
    });
    /** @type {NodeListOf<HTMLSelectElement>} */
    const appellation_selectors = document.querySelectorAll('select.appellation-selector');

    const form_echantillon = document.getElementById('inscription-echantillon');
    const code_promo_fieldset = document.getElementById('code-promo-fieldset');
    const selector_pays_facture = document.getElementById('pays-facture-selector');
    const recaps = document.querySelectorAll('.recap-prix');

    if(form_inscription instanceof HTMLFormElement) {
        form_inscription.addEventListener('submit', function (e) {
            const code = selector_pays.selectedOptions[0].dataset.code;

            if (code === 'ZZ') { // Code pour le pays 'Autre'
                if (!confirm('Si vous n\'avez pas trouvé votre pays dans la liste déroulante, nous vous conseillons de nous contacter au +33 (0)5.57.68.25.66 afin que nous le rajoutions et que vos échantillons soient intégrés au bon pays.\n' +
                    '\n' +
                    'Cliquez sur ok pour finalement conserver autre comme pays, sinon cliquez sur annuler.')) { // todo: traduction
                    e.preventDefault();
                }
            }
        });
    }

    if(selector_pays instanceof HTMLSelectElement) {
        selector_pays.addEventListener('change', function () {
            const option = selector_pays.selectedOptions[0];
            for (const group of tva_groups) {
                updateTVA(option, group.prefix, group.input, group.required);
            }
            for (const group of tel_groups) {
                updatePhone(option, group.prefix);
            }
            for (const selector of appellation_selectors) {
                updateAppellations(selector_pays, selector);
            }
        });

        const pays = selector_pays.selectedOptions[0];

        if(pays instanceof HTMLOptionElement) {
            for (const group of tva_groups) {
                updateTVA(pays, group.prefix, group.input, group.required);
            }
            for (const group of tel_groups) {
                updatePhone(pays, group.prefix);
                // On autorise uniquement la saisie de chiffres dans le champ téléphone
                group.input.addEventListener('input', function (e) {
                    if (!/[0-9() ]+/.exec(e.data)) {
                        e.target.value = e.target.value.replace(/[^0-9() ]+/g, '');
                    }
                });
                group.input.dispatchEvent(new InputEvent('input'));
            }
            for (const selector of appellation_selectors) {
                updateAppellations(selector_pays, selector);
            }
        }
    }

    if(form_echantillon instanceof HTMLFormElement && selector_pays_facture instanceof HTMLSelectElement) {
        const code_promos = form_echantillon.elements.namedItem('code_promo');
        if(code_promo_fieldset instanceof HTMLFieldSetElement && code_promos instanceof RadioNodeList) {
            code_promo_fieldset.addEventListener('change', function (e) {
                console.debug(e.target);
                if (e.target.name === 'code_promo') {
                    for (const recap of recaps) {
                        updateRecap(recap, e.target, selector_pays_facture.selectedOptions[0]);
                    }
                }
            });
        }

        selector_pays_facture.addEventListener('change', function (e) {
            for (const recap of recaps) {
                updateRecap(recap, findChecked(code_promos), selector_pays_facture.selectedOptions[0]);
            }
        });

        for (const recap of recaps) {
            updateRecap(recap, findChecked(code_promos), selector_pays_facture.selectedOptions[0]);
        }
    }
});
