document.addEventListener('DOMContentLoaded', function (e) {
    const currency_formatter = new Intl.NumberFormat(document.documentElement.lang, {style: 'currency', currency: 'EUR'});

    const form_panier_boutique = document.getElementById('panier-boutique');
    const code_promo_fieldset = document.getElementById('code-promo-fieldset');

    const adresse_diff = document.getElementById('adresse_diff'); // checkbox qui indique si on utilise la même adresse de facturation ou pas
    const adresse_facture = document.getElementById('adresse_facture');

    const pays_livr_selector = document.getElementById('adresse[LIV][pays-selector]')
    const pays_fact_selector = document.getElementById('adresse[FAC][pays-selector]')

    const panier_table = document.querySelectorAll('.panier-totaux');

    /** On affiche ou on masque le fieldset de l'adresse de facturation, en fonction de choix du client d'utiliser l'adresse de livraison pour les deux ou pas. */
    if (form_panier_boutique instanceof HTMLFormElement && adresse_diff instanceof HTMLInputElement && adresse_facture instanceof HTMLFieldSetElement && pays_livr_selector instanceof HTMLSelectElement && pays_fact_selector instanceof HTMLSelectElement) {
        const code_promos = form_panier_boutique.elements.namedItem('code_promo');
        if (code_promo_fieldset instanceof HTMLFieldSetElement && code_promos instanceof RadioNodeList) {
            code_promo_fieldset.addEventListener('change', function (e) {
                console.debug(e.target);
                if (e.target.name === 'code_promo') {
                    for (const panier of panier_table) {
                        updatePays(adresse_diff, pays_livr_selector, pays_fact_selector, panier_table, e.target);
                    }
                }
            });
        }

        adresse_diff.addEventListener('change', function (e) {
            updateAdresseDiff(adresse_diff, findChecked(code_promos));
        })

        pays_livr_selector.addEventListener('change', function (e) {
            updatePays(adresse_diff, pays_livr_selector, pays_fact_selector, panier_table, findChecked(code_promos));
        });

        pays_fact_selector.addEventListener('change', function (e) {
            updatePays(adresse_diff, pays_livr_selector, pays_fact_selector, panier_table, findChecked(code_promos));
        });

        updateAdresseDiff(adresse_diff, findChecked(code_promos));
        updatePays(adresse_diff, pays_livr_selector, pays_fact_selector, panier_table, findChecked(code_promos));
    }

    function updateMontant(panier, pays, promo) {
        const tva_element = panier.querySelector('.panier-montant-tva');
        const ttc_element = panier.querySelector('.panier-montant-ttc');
        const promo_element = panier.querySelector('.panier-promo');

        const taux_tva = parseFloat(pays.dataset.tauxTva);
        const tva_requise = (pays.dataset.numFiscalRequis === '1') || (panier.dataset.clientNumFiscalEmpty === "1");

        const ht = parseFloat(panier.dataset.montantHt);
        const ttc = parseFloat(panier.dataset.montantTtc);
        const frais_applicable = panier.dataset.fraisPortApplicable === '1';
        const frais_ht = frais_applicable ? parseFloat(panier.dataset.fraisPortHt) : 0.0;
        const frais_ttc = frais_applicable ? parseFloat(panier.dataset.fraisPortTtc) : 0.0;

        if (promo === null || promo.value === '') {
            promo_element.classList.add('hidden');
            if (tva_requise) {
                tva_element.textContent = currency_formatter.format((ht + frais_ht) * taux_tva);
                ttc_element.textContent = currency_formatter.format((ht + frais_ht) * (1 + taux_tva));
            } else {
                tva_element.textContent = currency_formatter.format(0);
                ttc_element.textContent = currency_formatter.format(ht + frais_ht);
            }
        } else {
            const promo_montant = panier.querySelector('.panier-montant-promo');
            const promo_amount = parseFloat(promo.dataset.amount);
            promo_montant.textContent = currency_formatter.format(promo_amount);
            if (tva_requise) {
                tva_element.textContent = currency_formatter.format((ht + frais_ht - promo_amount) * taux_tva);
                ttc_element.textContent = currency_formatter.format((ht + frais_ht - promo_amount) * (1 + taux_tva));
            } else {
                tva_element.textContent = currency_formatter.format(0);
                ttc_element.textContent = currency_formatter.format(ht + frais_ht - promo_amount);
            }
            promo_element.classList.remove('hidden');
        }
    }

    function updateAdresseDiff(adresse_diff, promo) {
        adresse_facture.classList.toggle('hidden', adresse_diff.checked);
        adresse_facture.disabled = adresse_diff.checked;

        for (const panier of panier_table) {
            updateMontant(panier, (adresse_diff.checked ? pays_livr_selector.selectedOptions[0] : pays_fact_selector.selectedOptions[0]), promo);
        }
    }

    function updatePays(adresse_diff, pays_livr_selector, pays_fact_selector, panier_table, promo) {
        // On change les frais de port ici, et si on garde la même adresse pour la livraison et la facturation, on change aussi la TVA
        const pays_livr = pays_livr_selector.selectedOptions[0];
        const pays_fact = pays_fact_selector.selectedOptions[0];
        const frais = parseFloat(pays_livr.dataset.fraisPort);
        for (const panier of panier_table) {
            const frais_port_element = panier.querySelector('.panier-montant-frais-port');
            panier.dataset.fraisPortHt = pays_livr.dataset.fraisPort;
            panier.dataset.fraisPortTtc = (parseFloat(pays_livr.dataset.fraisPort) * parseFloat(adresse_diff.checked ? pays_livr.dataset.tauxTva : pays_fact.dataset.tauxTva)).toString();
            const frais_applicable = panier.dataset.fraisPortApplicable === '1';
            frais_port_element.textContent = currency_formatter.format(frais_applicable ? frais : 0.0);

            updateMontant(panier, (adresse_diff.checked ? pays_livr : pays_fact), promo);
        }
    }

    /**
     * @param {RadioNodeList|null} radio_list
     * @returns {node|null}
     */
    function findChecked(radio_list) {
        let node = null;

        if (radio_list instanceof RadioNodeList) {
            for (const radio of radio_list) {
                if (radio.checked) {
                    node = radio;
                    break;
                }
            }
        }

        return node;
    }
});
