document.addEventListener('DOMContentLoaded', function (e) {
    const zone_ups_price_table = document.getElementById('zone-ups-price-list');
    const tranche_prix_template = document.getElementById('template-tranche-prix-row');

    if (zone_ups_price_table instanceof HTMLElement && tranche_prix_template instanceof HTMLElement) {
        let row_index = Array.from(zone_ups_price_table.children).filter(function (element) {
            return element.matches('tr');
        }).length;

        zone_ups_price_table.addEventListener('click', function (e) {
            if (e.target.closest('.add-zone-ups-price')) {
                const clone = tranche_prix_template.content.cloneNode(true);

                const min = clone.querySelector('input[data-name="min_echantillon"]');
                const price_type = clone.querySelector('select[data-name="price_type"]');
                const prices = clone.querySelectorAll('input[data-name="price"]');

                min.name = `prices[${row_index}][min_echantillon]`;
                price_type.name = `prices[${row_index}][price_type]`;

                for (const price of prices) {
                    price.name = `prices[${row_index}][price][${price.dataset.zone}]`;
                }

                zone_ups_price_table.appendChild(clone);
                row_index++;
            }
            if (e.target.closest('.remove-zone-ups-price')) {
                const parent = e.target.closest('tr');
                if (parent !== null) {
                    parent.remove();
                    /**
                     * On ne décrémente pas l'index puisqu'on ne supprime pas nécessairement la dernière ligne.
                     * L'intérêt est par ailleurs de grouper un min, un max, et un prix ensemble, sans collision
                     * pas spécialement de les ordonner.
                     */
                }
            }
        })
    }
});
