document.addEventListener('DOMContentLoaded', function (e) {
    /** On sélectionne le formulaire d'édition des échantillons dans le bo. */
    const echantillon_edit_form = document.getElementById('admin-echantillon-edit');

    if (echantillon_edit_form instanceof HTMLFormElement) {
        const famille_selector =  echantillon_edit_form.querySelector('[name="famille_id"]');
        /** @var {NodeListOf<HTMLOptionElement>} */
        const options = echantillon_edit_form.querySelectorAll('option[data-categorie-vin], option[data-famille]');

        function filter() {
            let selected_categories = [];
            /** On construit la liste de toutes les catégories correspondantes "aux" familles sélectionnées
             * (on ne peut en sélectionner qu'une de toute façon, mais on reçoit une liste) */
            for(const selected_opt of famille_selector.selectedOptions) {
                selected_categories = selected_categories.concat(selected_opt.dataset.categorieVin.split(','));
            }

            /** On parcours toutes les options avec les datasets appropriés
             * en excluant celles de la liste de familles. */
            for(const opt of options) {
                if (!famille_selector.contains(opt)) {
                    let opt_categories = [];
                    /** Si elle a un dataset de catégories, on en fait un tableau */
                    if(typeof opt.dataset.categorieVin === "string") {
                        opt_categories = opt.dataset.categorieVin.split(',');
                    }
                    /** On regarder si l'option appartient à au moins une des catégories de la famille sélectionnée.  */
                    const match_categorie = opt_categories.some(function (cat) {
                        return selected_categories.includes(cat);
                    })
                    /** Si on a une appartenance à une catégorie, ou directement à la famille, l'option est valide. */
                    if((match_categorie) || (opt.dataset.famille === famille_selector.value)) {
                        opt.disabled = false;
                        opt.classList.remove('hidden');
                        toggle_select(opt.closest('select'));
                    }
                    /** Sinon, on la désactive. */
                    else {
                        opt.disabled = true;
                        opt.classList.add('hidden');
                        toggle_select(opt.closest('select'));
                    }
                }
            }
        }

        /**
         * Active ou désactive le select en paramètre, si toutes les options
         * avec une valeur qu'il contient sont désactivées.
         * @param {HTMLSelectElement} select
         */
        function toggle_select(select) {
            select.disabled = [...select.options].every(function (opt) {
                return opt.disabled || opt.value === "";
            })
        }

        if (famille_selector instanceof HTMLSelectElement) {
            famille_selector.addEventListener('change', function (e) {
                filter()
            });

            filter();
        }
    }
});
