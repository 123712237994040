document.addEventListener('DOMContentLoaded', function (e) {
    document.addEventListener('click', function (e) {
        const target = e.target.closest('.comment-edit-toggle');

        if (target instanceof HTMLElement) {
            const container = target.closest('.comment-container');
            if (container instanceof HTMLElement) {
                const show = container.querySelector('.comment-show');
                const edit = container.querySelector('.comment-edit');

                if (show instanceof HTMLElement && edit instanceof HTMLElement) {
                    show.classList.add('hidden');
                    edit.classList.remove('hidden');
                }
            }
        }
    })
});
