document.addEventListener('DOMContentLoaded', function (e) {
    let quills = [];
    let inputs = [];
    const focus_editors = document.querySelectorAll('.focus-editor');

    for(const editor of focus_editors) {
        const name = editor.dataset.name;
        quills[name] = new Quill(editor, {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                    ['blockquote'],

                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                    [{ 'color': [
                        '#B2986D', '#866d46',
                        '#B7CE21', '#A8B400',
                        '#EBEBEB', '#A1A1A1', '#757575', '#333333',
                        '#ff2927', '#FF002B', '#A70000',
                        '#991202', '#7e0e02', '#640b02',
                    ] }, { 'background': [
                        '#B2986D', '#866d46',
                        '#B7CE21', '#A8B400',
                        '#EBEBEB', '#A1A1A1', '#757575', '#333333',
                        '#ff2927', '#FF002B', '#A70000',
                        '#991202', '#7e0e02', '#640b02',
                    ] }],

                    [{ 'align': [] }],

                    ['image', 'video'],

                    ['clean']                                         // remove formatting button
                ]
            },
            theme: 'snow'
        });

        inputs[name] = document.querySelector(`input[name="${name}"]`);

        try {
            const content = JSON.parse(inputs[name].value);
            quills[name].setContents(content);
        } catch { /** Si la valeur existante est un JSON valide, on utilise cette valeur, sinon, on ne fait rien de spécial et l'éditeur sera vide. */ }

        const form = editor.closest('form');
        form.addEventListener('submit', function (e) {
            inputs[name].value = JSON.stringify(quills[name].getContents());
        });
    }
});
