export function trans(text, data=null) {
    let translation = text;
    if(translations[document.documentElement.lang] !== undefined) {
        translation = translations[document.documentElement.lang][text] ?? text;
    }

    for (const key in data) {
        translation = translation.replace(`:${key}`, data[key]);
    }

    return translation;
}

const translations = {
    fr: {

    },
    en: {
        "Le fichier sélectionné doit faire moins de :value Mo.": "The selected file size must be less than :value MB.",
        "Votre mot de passe doit faire au minimum 8 caractères, contenir 1 minuscule, 1 majuscule, 1 chiffre et 1 caractère spécial.": "Your password must be at least 8 characters long, contain 1 lowercase, 1 uppercase, 1 number, and 1 special character.",
        "Le mot de passe et sa confirmation ne correspondent pas.": "The password and its confirmation don't match.",
        "TVA (:taux)": "VAT (:taux)",
        "Au moins :count case doit être sélectionnée.": "At least :count box must be ticked."
    },
    es: {
        "Le fichier sélectionné doit faire moins de :value Mo.": "El archivo debe tener un máximo de :value MB.",
        "Votre mot de passe doit faire au minimum 8 caractères, contenir 1 minuscule, 1 majuscule, 1 chiffre et 1 caractère spécial.": "Su contraseña debe tener al menos 8 caracteres, contener 1 minúscula, 1 mayúscula, 1 número y 1 carácter especial.",
        "Le mot de passe et sa confirmation ne correspondent pas.": "La contraseña y su confirmación no coinciden.",
        "TVA (:taux)": "IVA (:taux)",
        "Au moins :count case doit être sélectionnée.": "Debe marcarse al menos :count casilla."
    }
};
