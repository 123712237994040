document.addEventListener('DOMContentLoaded', function (e) {
    const mode_paiement_selector = document.getElementById('mode-paiement-selector');
    const ref_groups = [...document.querySelectorAll('.paiement-ref-group')].map(function (val) {
        return {
            input: val.querySelector('.paiement-ref-input'),
            prefix: val.querySelector('.paiement-ref-prefix'),
        };
    });

    /**
     * @param {HTMLOutputElement} output
     * @param {String} prefix
     */
    function update_ref_prefix(output, prefix) {
        if (prefix !== '' && prefix !== undefined) {
            output.textContent = prefix;
            output.classList.remove('hidden');
        } else {
            output.classList.add('hidden');
        }
    }

    if (mode_paiement_selector instanceof HTMLSelectElement) {
        mode_paiement_selector.addEventListener('change', function () {
            if (mode_paiement_selector.selectedOptions[0] instanceof HTMLOptionElement) {
                for (const group of ref_groups) {
                    update_ref_prefix(group.prefix, mode_paiement_selector.selectedOptions[0].dataset.prefix);
                }
            }
        });

        if (mode_paiement_selector.selectedOptions[0] instanceof HTMLOptionElement) {
            for (const group of ref_groups) {
                update_ref_prefix(group.prefix, mode_paiement_selector.selectedOptions[0].dataset.prefix);
            }
        }
    }
});
