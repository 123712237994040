document.addEventListener('DOMContentLoaded', function (e) {
    function getZoneId(zone) {
        return zone === undefined ? null : parseInt(zone);
    }

    function selectAllChange(options, zone) {
        for (const opt of options) {
            const opt_zone = getZoneId(opt.dataset.zone);
            opt.selected = opt_zone !== zone;
        }
    }

    const zones_selects = document.querySelectorAll('select.zone-selector');
    const buttons_move_zone = document.querySelectorAll('button.move-to-zone');
    const form_zones_ups = document.getElementById('zone-ups-form');

    if (zones_selects.length > 0 && buttons_move_zone.length > 0 && form_zones_ups instanceof HTMLFormElement) {
        const zones_selects_map = new Map();
        for (const zone_select of zones_selects) {
            const zone_id = getZoneId(zone_select.dataset.zone);
            zones_selects_map.set(zone_id, zone_select);
        }

        form_zones_ups.addEventListener('click', function (e) {
            const button_move_zone = e.target.closest('button.move-to-zone');
            if (button_move_zone instanceof HTMLButtonElement && [...buttons_move_zone].includes(button_move_zone)) {
                const zone_from = getZoneId(button_move_zone.dataset.zoneFrom);
                const zone_to = getZoneId(button_move_zone.dataset.zoneTo);

                const select_from = zones_selects_map.get(zone_from);
                const select_to = zones_selects_map.get(zone_to);

                if (select_from instanceof HTMLSelectElement && select_to instanceof HTMLSelectElement) {
                    const selected_options = [...select_from.selectedOptions];
                    for (const opt of selected_options) {
                        select_to.appendChild(opt);
                    }
                }
            }
        });

        form_zones_ups.addEventListener('submit', function (e) {
            for (const [zone, selector] of zones_selects_map) {
                selectAllChange(selector.options, zone)
            }
        });
    }
});
